<script setup>
	import { useGetPostsQuery } from '~/composables/urql';

	const { data } = await useGetPostsQuery({
		variables: {
			offset: 0,
			limit: 10,
			order_by: {
				views: 'desc'
			},
			where: {}
		}
	});
</script>

<template>
	<Section title="Популярные посты" class="shadow">
		<article class="grid gap-1 px-4 py-2 text-sm" v-for="post in (data.uogorod_posts || [])" :key="post.id">
			<Author :user="post.author">
				<template #fio>
					<Icon class="mr-1" name="ic:outline-access-time" size="12px" />
					<span class="text-xs">{{ relativeDate(post.created_at) }}</span>
				</template>
			</Author>

			<nuxt-link class="post_content" :to="'/feed/' + post.id">
				{{ post.title }}
			</nuxt-link>
			
			<!--<PostCounter class="text-xs" :count="post._count" :views="post.views" :entity-id="post.id"></PostCounter>-->
		</article>
	</Section>
	<section class="ml-auto px-0 w-full rounded-xl xl:sticky z-20 top-[60px]">
		<AdContainer id="yandex_rtb_R-A-209899-9" />
	</section>
</template>
