<template>
    <div class="grid xl:flex gap-4 items-start">
		<div class="grid gap-4 w-full">
			<slot></slot>
		</div>
    	<aside class="flex-shrink-0 grid gap-4 xl:w-[300px]">
			<SideNewComments />
			<SidePopularPosts />
		</aside>
	</div>
</template>