<script setup>
	import { relativeDate } from '~/utils/dateHelper';
	import { useGetCommentsQuery } from '~/composables/urql';
	
	const { data, fetching } = await useGetCommentsQuery({
		variables: {
			limit: 5
		}
	});
</script>

<template>
	<section class="ml-auto px-0 w-full rounded-xl">
		<AdContainer id="yandex_rtb_R-A-209899-7" />
	</section>
	<Section title="Новые коментарии" class="shadow">
		<template #icon>
			<svg-icon class="mr-2" name="chat-start" width="20" height="20"/>
		</template>
		
		<div class="grid gap-1 px-4 py-2 text-sm" v-for="comment in (data?.uogorod_comments || [])" :key="comment.id" v-loading="fetching">
			<Author :user="comment.author">
				<template #fio>
					<Icon class="mr-1" name="ic:outline-access-time" size="12px" />
					<span class="text-xs">{{ relativeDate(comment.created_at) }}</span>
				</template>
			</Author>
			<nuxt-link :to="`/feed/${comment.entity_id}#comment-${comment.id}`">
				{{ comment.text }}
				<!--<div class="flex items-center text-xs opacity-m mt-2">
					<Icon v-if="comment.entity_type == 'Post'" class="mr-1" name="fluent:feed-28-regular" />
					<Icon v-else-if="comment.entity_type == 'Garden'" class="mr-1" name="fluent:feed-28-regular" />
					Подробнее
				</div>-->
			</nuxt-link>
		</div>
	</Section>
</template>